/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define([
    'jquery',
    'mage/smart-keyboard-handler',
    'mage/mage',
    'domReady!'
], function ($, keyboardHandler) {
    'use strict';

	$(window).scroll(function() {
        if ($(this).scrollTop() > 300) {
            $('#back_top').fadeIn();
        } else {
            $('#back_top').fadeOut();
        }
    });
    $("#back_top").click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });
    $(".product-info-main .data.item.title").click(function(){
        setTimeout(function (){
            $('.product.media').trigger("dimensionsChanged");
        }, 500);
    });

    $('.cart-summary').mage('sticky', {
        container: '#maincontent'
    });

    $('.panel.header > .header.links').clone().appendTo('#store\\.links');
    $('#store\\.links li a').each(function () {
        var id = $(this).attr('id');

        if (id !== undefined) {
            $(this).attr('id', id + '_mobile');
        }
    });
    $('.marketplace-header-links').parent().addClass('marketplace-header-active');
    $('.custom-filter-by').click(function(){
        $('#layered-filter-block').toggleClass('active');
        $('body').toggleClass('filter-active');

    });
    $(window).scroll(function () {
        let getHeaderHeight = $('.page-header').innerHeight();
        let totalHeight = ($('.page-header').outerHeight()) + ($('.header-bottom').outerHeight());
        let scroll = $(window).scrollTop();
        if(scroll >= 1) {
            $(".page-header").addClass("sticky active");

        }
        else{
            $(".page-header").removeClass("sticky active");

        }
    });
    keyboardHandler.apply();
});
